import React from "react"

export default function FourZeroFour() {
  return (
    <>
      <h1>404</h1>
      <p>Sorry custom 404 coming!</p>
    </>
  )
}
